.NavBar {
  position:sticky;
  top: 0;
  color: white;
  width:fit-content;
  margin:auto;
  z-index: 999;
}
.NavBar ul {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding:0;
  overflow: hidden;
}
.NavBar li {
  display:inline-flex;
  background: #00A1B5 0% 0% no-repeat padding-box;
}

.NavBar li a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 8px 20px 8px 20px;
}

.NavBar li.separated {
  background: var(--medel-primary-color) 0% 0% no-repeat padding-box;
  margin-left:5px;
}
.NavBar li a:hover {background: #007C83 0% 0% no-repeat padding-box;}
.NavBar.subpage{
  width:100%;
  background-color: var(--medel-subnavbar-color);
}
.NavBar.subpage li {background-color: var(--medel-subnavbar-color);}
.NavBar.subpage .NavBar-wrapper{
  max-width:var(--content-wrapper-width);
  margin:auto;
}
.NavBar.subpage .NavBar-wrapper ul {
  width:fit-content;
  margin-left: 10px;
}
.NavBar.subpage .NavBar-wrapper li a {padding: 8px 20px 8px 0px;}
.NavBar.subpage .NavBar-wrapper li a:hover {background-color: var(--medel-subnavbar-color);}
.NavBar.subpage .NavBar-wrapper li a::before {
  content: "|";
  margin-right:20px;
  margin-left:0;
}
.NavBar.subpage .NavBar-wrapper li:last-child a::after {
  content: "|";
  margin-left:20px;
}

.NavBar.subpage .NavBar-wrapper amplify-sign-out.hydrated {
  font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;
}