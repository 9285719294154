@font-face {
  font-family: "Open Sans";
  src: url("./font/OpenSans-Regular.woff2") format("woff2");
  font-display: swap
}
@font-face {
  font-family: "Font Awesome 5 Free";
  src: url("./font/fa-solid-900.woff2") format("woff2");
  font-display: swap
}
@font-face {
  font-family: "SignaWebPro-Light";
  src: url("./font/SignaWebPro-Light.woff") format("woff");
  font-display: swap
}

.App {
  text-align: left;
  font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: black;
  background-color: white;
}

:root {
  --content-wrapper-width: 75rem;
  --medel-primary-color: #c60c30;
  --medel-subnavbar-color: #981e32;
  --medel-popup-button-color: #979594;
  --medel-contactus-color: #979594;
  --medel-footer1-bgd-color: #4d4d4d;
  --medel-footer2-bgd-color: #000;
  --medel-footerlinks-bgd-color: #4d4d4d;
  --medel-footeropenpositiononline-bgd-color: #000;
  --medel-blue-1: #00A1B5;
  --amplify-primary-color: #981e32;
  --amplify-primary-tint: #c60c30;
  --amplify-primary-shade: #c60c30;
  --amplify-font-family: 'Open Sans', 'Arial', 'Helvetica' ,sans-serif;
}

h1, .h1, h2, .h2, h3, .h3, h4 , .h4{
  font-family: SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif;
  color: var(--medel-primary-color);
}

h1, .h1 {
  font: normal normal 300 42px/46px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;
  letter-spacing: 0px;
}
h2, .h2 {
  font: normal normal 300 28px/31px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;
  letter-spacing: 0px;
}
h3, .h3 {
  font: normal normal 300 21px/23px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif !important;
  letter-spacing: 0px;
}
h4, .h4 {
  font: normal normal 300 17px/20px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif !important;
  letter-spacing: 0px;
}
h5, .h5 {
  font: normal normal 600 16px/24px Open Sans, Arial, Helvetica, sans-serif !important;
  letter-spacing: 0px;
}
h6, .h6 {
  font: normal normal normal 10px/15px Open Sans,Arial,Helvetica,sans-serif !important;
  letter-spacing: 0px;
  color: #646464 !important;
}
.openposition-header {
  margin: 0.2rem 0rem 0.5rem 0rem;
}

p, .p {line-height: 1.5; font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;}

p small{
  font: normal normal normal 14px/20px Open Sans, Arial, Helvetica, sans-serif !important;
  letter-spacing: 0px;
}

a {text-decoration: none;color:var(--medel-primary-color);}

.text-black {color: black!important;}
.text-gray {color: #979594!important;}
.text-white {color: white!important;}

.size21 {
  font: normal normal 300 21px/23px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif;
  letter-spacing: 0px;
  color: white;
}

.no-space {margin:0;}
.tasks-profile {margin:9px 0px; padding-left: 1rem; font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;}

.LanguageSelector {display: inline-flex; cursor: pointer; margin-left:calc(10px - 2px);}
.Language {margin:0 4px 0 4px;}
.Language.selected {font-weight: bold;}

.hiddenLanguageSelector {
  cursor: default;
}

img{
  object-fit: cover;
  width:100%;
  object-position: 50% 25%;
}

.NavigationList .dx-item-content {font: normal normal normal 16px Open Sans;}
.NavigationList {border-bottom: 1px solid #ddd;}
.NavigationList .MenuItem * {color: #717272;}

.NavigationListLogo {
  background: #717272;
  color:white;
  padding:0.6rem 0.5rem 0.5rem 0.8rem;
}

.NavigationListLogo img{
  width:60%;
  padding:0;
  margin:0;
}

.NavigationListLanguage {
  position: relative;
  color: #717272;
  font: normal normal normal 16px Open Sans;
  top: 2rem;
}

.NavigationListLanguage .LanguageSelector {display: inline-flex; cursor: pointer; margin-left: 0.4rem; width:100%;}

.NavigationDrawer .dx-drawer-panel-content {
  background-color: #f0f0f0;
  border-right: 1px solid #ddd;
}

.NavigationDrawer .dx-toolbar {
  background-color: var(--medel-primary-color);
  padding: 5px 10px;
}

.NavigationDrawer .dx-toolbar .dx-button-mode-contained, .NavigationDrawer .dx-button.dx-state-focused , .NavigationDrawer .dx-button.dx-state-hover{
  background-color: var(--medel-primary-color);
  border:0;
}

.dx-button-mode-contained .dx-icon {color: white !important;}
.NavigationDrawer .dx-drawer{border-color: #717272;}

.mobile-toolbar-logo{height: 20px; width:105px;}
.mobile-toolbar-logo img{object-fit: contain;}

.ManagePositions .Content { max-width: 90%;}
.ManageJobApplications .Content { max-width: 90%;}
.ManageJobApplications .dx-button-mode-contained .dx-icon {color: var(--medel-popup-button-color) !important;}
.ManageContactMessages .Content { max-width: 90%;}
.ManageContactMessages .dx-button-mode-contained .dx-icon {color: var(--medel-popup-button-color) !important;}
.ManagePositions .dataGridCellActive {background-color: green; color: green;}
.ManagePositions .dataGridCellInactive {background-color: red; color: red;}
.ManagePositions .dx-button-mode-contained .dx-icon {color: var(--medel-popup-button-color) !important;}
.ManagePageHeadline .Content {padding: 1rem 1rem 0rem;}
.dx-link {color: var(--medel-primary-color)!important;}

.Content{
  max-width: var(--content-wrapper-width);
  margin:auto;
  padding: 60px 1rem 80px 1rem;
}

.Content.small{
  max-width: var(--content-wrapper-width);
  margin:auto;
  padding: 40px 1rem;
}

.Content.small h3 {
  font: normal normal 300 21px/23px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif;
  letter-spacing: 0px;
  padding:0px;
  margin:0;
  color: white;
}

.Content.OneToThree {
  display: grid;
  grid-column-gap:1rem;
  grid-row-gap:2rem;
}

.CitationContent{
  max-width: var(--content-wrapper-width);
  margin:auto;
  padding: 80px 1rem 60px 1rem;
}

.spaceBottom {
  margin-bottom: 2em;
}

.highlighted{
  width: 100%;
  background-color: #f0f0f0;
}

.highlighted1{
  width: 100%;
  background-color: #979594;
  color:white;
  overflow: auto;
}

.highlighted2{
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAIAAABvFaqvAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhCNEY4MUIwNEU0RTExRTY5NURBODZERTNFNTMxQTFBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhCNEY4MUIxNEU0RTExRTY5NURBODZERTNFNTMxQTFBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEI0RjgxQUU0RTRFMTFFNjk1REE4NkRFM0U1MzFBMUEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEI0RjgxQUY0RTRFMTFFNjk1REE4NkRFM0U1MzFBMUEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5c7AZ5AAAB40lEQVR42mSVUY7CMAxE0zQgBOL+5+MICFQhoHQneelguv7oOia2x2PHO1wul9PplHNOKd3v9/P5vCzL5/O53W7SZdzv99fr9XA4yK5r0zRJH8dRuu4cj0d8y9Jknmcd5C99GIa8io7v91u/yihnfpKyrDKsUoPZU4qOvoS/oqNYBxrAyV0RkQFEDpGaOHppgh1o1p07z00MKq3CUQlxiFVzlF0RlUDHGoiQm+pcuWuUEEvf1+uVgmDMCrnb7Sie+mPQHMRVgNcc4VX0R32JNKPI+Hw+8eGLDxURxUzVC2MTEvZGNpFRSF0maWVBr7W0BISriIw21gxMkJpmQyYNfLvSXpr7Fbn37BCIBARyVtdR3GkUdwp/6CC68eoLWKBhLM4WBzIe4xzgnH6lV+f+xRDmGyLilPbxW5mmj7U0GvG/tE0HXJ2hGUt/a5FO+hrdGDy/KQ83kDXMnsw+vjIxpt9HuBIJRj0LCOYluCHeMDkmYeN4sh+PR6TTpJCP+/AoYzE1kUiSsDpAGkcmEvQlwdmIYoAom0frn9gBeox0vJPt0TB/QJCiAnE2IpAyk1LMY2ElEwjd79D9IpN3E9HjApBS4hpl3ZlLbvjRxQ25oey7j/T1Pwnv0Djim2fk3H6PfwIMAJ3OrC5UeVWjAAAAAElFTkSuQmCC) repeat;
  overflow: auto;
}

.highlighted3 {
  width: 100%;
  background-color: var(--medel-contactus-color);
  color: white;
}

.highlighted-red{
  background-color: #F7403A;
  background-color: rgba(247, 64, 58, 0.15);
}

.Text.emph {
  text-align: center;
  font-style: italic;
  color: black;
}

.dx-button-content {font: normal normal normal 20px/24px Open Sans}

.Button.center {
  width:fit-content;
  margin:auto;
  border-radius: 0;
}

.Button {
  margin-top:1rem;
  margin-bottom:1rem;
}

.Button .dx-button, .Button.center .dx-button, .Button .dx-button.dx-state-focused, .fileuploader-container .dx-fileuploader-button{
  background: var(--medel-primary-color) 0% 0% no-repeat padding-box;
  height:48px;
  color: white;
  border: 0px;
  border: 0px;
  border-radius: 0px;
}

.Button.gray .dx-button{
  background-color:#BEBEBE;
}

.PrevNextButton {
  display: flex;
  margin-top:50px;
}

.Button.prev .dx-button{
  width:fit-content;
  background-color:#646464;
}

.Button.prev .dx-button-text::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  margin-left: 5px;
  content: '\f060';
}

.Button.next .dx-button{
  width:fit-content;
  background: var(--medel-primary-color) 0% 0% no-repeat padding-box;
  height:48px;
}

.Button.next .dx-button-text::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  margin-left: 10px;
  content: '\f061';
}

.Button .dx-button.dx-state-hover, .fileuploader-container .dx-fileuploader-button.dx-state-hover{
  width:fit-content;
  background-color:#646464;
  color: white;
  height:48px;
}

.delete-button .dx-button .dx-icon {color: black !important;}

.ButtonRow {
  display: inline-flex;
  column-gap: 1rem;
  padding-top: 40px;
}

.ButtonRow .Button {
  margin:0;
}

.ActionBox .Image {max-height: 214px;}

.two .ActionBox .Text {
  padding-top: 1em;
  margin-left: 0rem ;
}

.angle-right::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  margin-left: 0.4rem;
  content:"\f105";
}

.Employees {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--content-wrapper-width)/7), 1fr));
  width:100%;
  margin:auto;
  row-gap:20px;
}

.six {
  display: grid;
  column-gap:1rem;
  row-gap:2rem;
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--content-wrapper-width)/8), 1fr));
}

.four {
  display: grid;
  column-gap:1rem;
  row-gap:1rem;
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--content-wrapper-width)/4.2), 1fr));
}

.three {
  display: grid;
  column-gap:20px;
  row-gap:20px;
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--content-wrapper-width)/3.2), 1fr));
}

.two {
  display: grid;
  row-gap: 2rem;
  column-gap: 1rem;
}

.padding-bottom {padding-bottom:2rem;}
.no-row-gap {row-gap: 0;}

.two .Image{max-width:100%;}
.two .Text.right{margin-left:5rem;}
.two .Text.left{margin-right:5rem;}

.px1015 {
  text-align: left;
  font: normal normal normal 10px/15px Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: black;
}

.px1624 {
  text-align: left;
  font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: black;
}

.Benefits {padding-top: 2rem;}

.Benefits.three p, .Benefits.three h5 {margin:0px;}

.Benefits .center{
  margin:auto;
  text-align:center;
}

.Benefits.six h5 {
  margin:0px;
  font: normal normal normal 14px/24px Open Sans !important;
  letter-spacing: 0px;
}

.WorkingAt .Benefit {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.Benefit .Icon img{width:48px; height: 48px;}

.MoreInfo {
  display: grid;
  column-gap:1rem;
  row-gap:2rem;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, calc(var(--content-wrapper-width)/4.2));
  padding-top: 38px;
  padding-bottom: 38px;
}

.OpenPositionHead h1{
  text-align: left;
  color: var(--medel-primary-color);
  max-width: 50%;
}

.OpenPositionHead h2{
  text-align: left;
  color: black;
}

.OpenPositionHead h3{
  text-align: left;
  font: normal normal 600 16px/24px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: #646464;
}

.MultiStepForm {background-color: #f0f0f0;}

.MultiStepForm h1 {
  margin:4px 0 4px 0;
  color: var(--medel-primary-color);
}

.MultiStepForm h5 {color: #646464;}

.MultiStepForm h6 {
  margin:0;
  font: normal normal normal 10px/15px Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: #646464;
}

.MultiStepForm label {font: normal normal normal 16px/21px Open Sans;}

.MultiStepForm .dx-popup-title {
  background-color: var(--medel-primary-color);
  color:white;
}

.MultiStepForm .dx-popup-content{
  text-align: center;
  margin: 0 2rem 1rem 2rem;
}

.MultiStepForm .dx-popup-content p{
  font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;
  margin: 0 1rem 1rem 1rem;
}

.MultiStepForm .custom-popuptitle{
  text-align: center;
  height: 160px;
  padding-top: 40px;
}

.MultiStepForm .custom-popuptitle img{
  width: 80px;
  height: 80px;
}

.MultiStepForm .dx-popup-content .Button .dx-button{
  background-color:var(--medel-popup-button-color);
  color:white;
}

.MultiStepForm .Form {
  max-width: 650px;
  margin: 3rem auto;
  padding:3rem;
  background-color: white;
}

.form-item {margin-bottom:1rem;}

.Step1{min-height: 25vh;}
.Step1 .initiative{padding-top: 100px;}

.Step5 hr{
  height:2px;
  border-width:0;
  color:#f0f0f0;
  background-color:#f0f0f0;
  margin-top:2vh;
}

.Step5 .DataProtection {width:80%;}
.Step5 .DataProtection A {text-decoration: underline; color: inherit;}
.Step5 .DataProtection .dx-checkbox {padding-right: 0.5rem;}
.Step5 .DataProtectionCheckbox {padding-top: 1rem;}
.Step5 {width:90%;}

.FileList {
  display:inline-grid;
  gap:1rem;
  padding:1rem;
}

.FileList .file {
  display: grid;
  text-align: left;
  grid-template-columns: repeat(auto-fill, minmax(10rem,1fr));
  gap:1rem;
}

.PreviewContent.full {
  display: grid;
  grid-template-columns: auto;
}

.PreviewContent {
  display: table;
  width:100%;
}

.PreviewContent .description{
  display: table-cell;
  font: normal normal normal 16px/23px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: #646464;
  width: 40%;
  padding-right:8px;
}

.PreviewContent .values{
  display: table-cell;
  font: normal normal 300 16px/23px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif;
  letter-spacing: 0px;
  color: black;
}

.PreviewContent .table-row {display: table-row;}
.PreviewRow {display: table-row;}

.fileuploader-container{
  min-height: 151px;
  border: 1px solid black;
  display: grid;
}

.fileuploader-container .dx-fileuploader-input{min-height: 150px;}

.fileuploader-container .dx-fileuploader-input-wrapper {
  display:flex;
  flex-direction:column-reverse;
}

.dx-fileuploader-button {width: max-content;}

.underline{text-decoration: underline;}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.carousel .control-dots {display: inline-block; height:20px;}
.carousel.carousel-slider {
  display: flex;
  flex-direction: column;
}
.carousel ul {
  margin-top: auto;
  margin:0;
}
.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
}
.carousel .slide { display: flex; justify-content: center; }

.JobsCarousel .carousel .control-dots .dot{
  box-shadow: 0px 0px 0px rgba(0,0,0,0);
  background: #BEBEBE;
  opacity:1;
}
.JobsCarousel .carousel .control-dots .dot.selected, .JobsCarousel .carousel .control-dots .dot:hover {
  background: #00A1B5;
  opacity:1;
}
.JobsCarouselWrapper {
  max-width: var(--content-wrapper-width);
  margin:auto;
  padding: 0rem 1rem 4rem 1rem;
}
.JobsCarouselWrapper .Button { padding-top: 40px; }

.dx-checkbox-checked .dx-checkbox-icon {
  font: 16px/16px DXIcons;
  color: #337ab7;
  text-align: center;
}

.dx-checkbox-indeterminate .dx-checkbox-icon::before {background-color: white !important;}

.dx-list .dx-empty-message {white-space: normal !important;}

.dx-empty-message {
  font: normal normal 300 21px/23px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif;
  word-wrap: break-word;
  max-width: 100%;
}

.FreeTextBefore, .FreeTextAfter {max-width: 66%;}
.FreeTextAfter p, .FreeTextAfter p {margin: 0px;}
.FreeTextBefore {margin-bottom:40px;}
.FreeTextAfter {margin-top: 40px;}

.PositionInfoTags{
  block-size: fit-content;
  display: flex;
  flex-wrap: wrap;
}

/*Loading Indicator*/
.ld-ind {
  display: inline-block;
  position: relative;
  min-width: 4rem;
  min-height: 4rem;
}
.ld-ind div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  margin: 0.5rem;
  border: 0.5rem solid #fff;
  border-radius: 50%;
  animation: ld-ind 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--medel-primary-color) transparent transparent transparent;
}
.ld-ind div:nth-child(1) {animation-delay: -0.45s;}
.ld-ind div:nth-child(2) {animation-delay: -0.3s;}
.ld-ind div:nth-child(3) {animation-delay: -0.15s;}
@keyframes ld-ind {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
/*Loading Indicator end*/

.EmployerAwards .Slogan p{
  font: 300 24px/34px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;
  color: #646464;
}

p.about-medel {
  color: #fff;
  margin: 10px auto 0;
}

.padBottom60px {
  padding-bottom: 60px;
}

@media screen and (min-width: 50em) {
  .two {grid-template-columns: repeat(2, 1fr);font: normal normal normal 16px/24px Open Sans,Arial,Helvetica,sans-serif;}
  .Content.OneToThree {grid-template-columns: minmax(30%, 1fr) minmax(60%, 3fr);}
}
@media screen and (max-width: 50em) {
  h1, .h1 {font: normal normal 300 30px/36px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;}
  h2, .h2 {font: normal normal 300 25px/28px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;}
  h3, .h3 {font: normal normal 300 20px/23px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;}
  h4, .h4 {font: normal normal 300 17px/20px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;}
  h5, .h5 {font: normal normal 600 16px/24px Open Sans, Arial, Helvetica, sans-serif !important;}
  .two{grid-template-columns: auto;}
  .three{grid-template-columns: auto;}
  .four{grid-template-columns: auto;}
  .six{grid-template-columns: auto;}
  .Benefits.six{grid-template-columns: repeat(auto-fit, minmax(10em, 1fr))}

  .two .Text.right {margin-left:0rem;}
  .two .Text.left {margin-right:0rem;}


  .OpenPositions .OneToThree {margin: 0;}
  .CitationContent{margin: 0 1rem 0 1rem;}
  .OpenPositionHead h1{max-width: unset;}

  .file .category::before{content:'(';}
  .file .category::after{content:')';}

  .MultiStepForm .Form { padding: 1rem; }

  .Step5 { width:100%; }

  .MoreInfo {
    display: grid;
    column-gap:1rem;
    row-gap:2rem;
    grid-template-columns: repeat(auto-fit, minmax(calc(var(--content-wrapper-width)/4.2), 1fr));
  }

  .SliderPrevButton, .SliderNextButton{display: none;}
  .Benefits.three {row-gap:2rem;}
  .FreeTextBefore, .FreeTextAfter {
    max-width: 100%;
  }

  .MultiStepForm .dx-popup-content{
    text-align: center;
    margin: 0 0 1rem 0;
    overflow-y: auto;
  }

  .MultiStepForm .dx-popup-content p{
    margin: 0 0 1rem 0;
  }
}

.SliderNextButton {
  position: absolute;
  background: transparent;
  color: #BEBEBE;
  border: 0;
  zIndex: 2;
  top: 0;
  width: 30px;
  height: 100%;
  cursor: pointer;
  right: 15px;
  padding: 0;
}

.SliderPrevButton {
  position: absolute;
  background: transparent;
  color: #BEBEBE;
  border: 0;
  zIndex: 2;
  top: 0;
  width: 30px;
  height: 100%;
  cursor: pointer;
  left: 15px;
  padding: 0;
}

@media screen and (max-width:480px) {
  .Benefit {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .Content, .Content.small {padding: 9mm 18mm;}
  .App {font: normal normal normal 12px/16px Open Sans,Arial,Helvetica,sans-serif;}
  h1, .h1 {font: normal normal 300 28px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;}
  h2, .h2 {font: normal normal 300 21px SignaWebPro-Light,Open Sans,Arial,Helvetica,sans-serif !important;}
  h3, .h3 {font: normal normal 300 16px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif !important;}
  h4, .h4 {font: normal normal 300 14px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif !important;}
  h5, .h5 {font: normal normal 600 12px Open Sans, Arial, Helvetica, sans-serif !important;}
  h6, .h6 {font: normal normal normal 10px Open Sans,Arial,Helvetica,sans-serif !important;}

  .openposition-header, .PositionInfoTags.OpenPosition { margin: 4.5mm 0 0 0;}
  .OpenPositionHead h6.openposition-header { margin: 0;}
  .OpenPositionHead h1 { max-width: 66%;}
  .tasks-profile {
    margin: 4.5mm 0 0 0;
    padding-left: 1rem;
  }
  .tasks-profile li{
    font: normal normal normal 16px/20px Open Sans,Arial,Helvetica,sans-serif;
    margin-top: 2mm;
  }

  .FreeTextBefore {margin-bottom: 9mm;}
  .FreeTextAfter {margin-top: 9mm;}
  .FreeTextBefore, .FreeTextAfter {max-width: 100%;}

  p, .p {font: normal normal normal 16px/15.6pt Open Sans,Arial,Helvetica,sans-serif;}
  p small{
    font: normal normal normal 14px/20px Open Sans, Arial, Helvetica, sans-serif !important;
    letter-spacing: 0px;
  }

  .Headline {
    height: 50mm;
    width: 100%;
  }
  .two {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4.5mm;
  }
  .four {grid-template-columns: repeat(4, 1fr);}
  .Benefits {padding-top: 4.5mm;}
  .Benefits.six{
    grid-template-columns: repeat(6, 1fr);
    gap:0;
  }
  .Benefits.six h5 {
    margin-top: 4.5mm;
    font: normal normal normal 14px / 16px Open Sans !important;
    letter-spacing: 0px;
  }

  p.about-medel {
    margin: 4.5mm auto 0;
    font: normal normal normal 14px/20px Open Sans, Arial, Helvetica, sans-serif !important;
  }

  .FooterOpenPositionOnline-wrapper {grid-template-columns: repeat(2, 1fr);}
  .no-print {display: none!important;}
  .pagebreak-before{
    padding-top: 18mm!important;
    page-break-before: always;
  }

  .small-print-font, .small-print-font * {
    font: normal normal normal 14px / 16px Open Sans !important;
  }
}