.Header {
  display: flex;
  flex-direction:column;
  background-color: black;
  color: white;
  width:100%;
  align-content: center;
}

.Header .Top{
  display: inline-grid;
  grid-template-columns: auto auto;
  align-content: center;
  margin: auto;
  max-width:var(--content-wrapper-width);
  width:100%;
  font-size: small;
  height:32px;
}

.Header .Bottom {
  width:100%;
  background-color: var(--medel-primary-color);
  height: 100px;
}

.Header .Bottom-wrapper {
  position:relative;
  max-width: var(--content-wrapper-width);
  height: 100%;
  margin:auto;
}

.Header .Logo {
  position:absolute;
  right:0;
  top: calc(50% - 20px);
  height:40px;
  width: 208px;
  padding-right:10px;
}

.Header .HeaderText {
  position:relative;
  font: normal normal 300 21px/21px SignaWebPro-Light, Open Sans, Arial, Helvetica, sans-serif !important;
  color: #F0F0F0;
  top: calc(50% + 5px);
  height: 21px;
  padding-left:10px;
}

.Header a{
  color: white;
  text-decoration: none;
}

.FastLinks {text-align: right;margin-right:10px;}

@media print {
  .Header .Bottom {
    height: 27mm;
  }

  .Header .Logo {
    height: 9mm;
    width: 47mm;
    top: calc(50% - 4.5mm);
    padding-right:9mm;
  }
}